import * as React from "react";

import Button from "../components/Button/Button.jsx";
import Group from "../components/Group/Group.jsx";
import Section from "../components/Section/Section.jsx";
import Text from "../components/Text/Text.jsx";

class BackendURLForm extends React.Component {
  constructor(props) {
    super(props);
    let backLoc = "http://127.0.0.1/sebastian/stripe/terminal-backend-php/?";
    let backPhp = "https://gcm.pe/?";
    let backrb = "https://backend-stripe-terminal.onrender.com/";
    this.state = {
      backendURL: backPhp,
    };
  }

  onFormInitialize = (event) => {
    event.preventDefault();
    this.props.onSetBackendURL(this.state.backendURL.trim());
  };

  onChangeBackendURL = (str) => {
    this.setState({ backendURL: str });
  };

  render() {
    const { backendURL } = this.state;
    return (
      <Section>
        <form onSubmit={this.onFormInitialize}>
          <Group direction="column" spacing={18}>
            <Text size={16} color="dark">
              TPV Online
            </Text>

            <Group direction="column">
              {/* <TextInput
                placeholder="https://yourserver.com"
                value={backendURL}
                ariaLabel="Backend URL"
                onChange={this.onChangeBackendURL}
              /> */}

              <Group
                direction="column"
                alignment={{ justifyContent: "space-between" }}
              >
                <Text size={12} color="lightGrey">
                  Conectar para comenzar a usar
                  {/* <Link
                    href="https://github.com/stripe/example-terminal-backend"
                    text="example backend"
                    newWindow
                  /> */}
                  , a la espera.
                </Text>
                <Button
                  disabled={backendURL === "" || backendURL === null}
                  color="text"
                  type="submit"
                >
                  <Text color="white" size={"1.3rem"}>
                    Conectar
                  </Text>
                </Button>
              </Group>
            </Group>
          </Group>
        </form>
      </Section>
    );
  }
}

export default BackendURLForm;
