import * as React from "react";

import { breakpoints } from "../../styles.jsx";
import { css } from "emotion";

const commonCSS = {
  background: "#ffffff",
  boxShadow: "0 3px 6px 0 rgba(0,0,0,0.05)",
  flexShrink: 0,
  padding: "10px",
  [breakpoints.laptop]: {
    width: "100%",
  },
  [breakpoints.mobile]: {
    width: "100%",
    maxWidth: "480px",
  },
  borderBottom: "1px solid #e3e8ee",
  display: "block",
  alignItems: "center",
  justifyContent: "space-between",
};

class Section extends React.Component {
  render() {
    const { alignment, children, position } = this.props;
    let borderRadius;
    switch (position) {
      case "first":
        borderRadius = "6px 6px 0 0";
        break;
      case "last":
        borderRadius = "0 0 6px 6px";
        break;
      case "middle":
        borderRadius = "0 0 0 0";
        break;
      default:
        borderRadius = "6px 6px 6px 6px";
    }

    return (
      <div
        className={css({
          ...commonCSS,
          borderRadius,
          ...alignment,
        })}
      >
        {children}
      </div>
    );
  }
}

export default Section;
