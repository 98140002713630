import * as React from "react";

import { css } from "emotion";

import Button from "../components/Button/Button.jsx";
import Group from "../components/Group/Group.jsx";
import Icon from "../components/Icon/Icon.jsx";
import Section from "../components/Section/Section.jsx";
import Text from "../components/Text/Text.jsx";

class ConnectionInfo extends React.Component {
  onChangeBackendURL = () => {
    this.props.onClickDisconnect();
    this.props.onSetBackendURL(null);
  };

  render() {
    const { backendURL, reader, onClickDisconnect } = this.props;

    return <Group direction="column" spacing={0} />;
  }
}

export default ConnectionInfo;
