import * as React from "react";

import Button from "../components/Button/Button.jsx";
import Icon from "../components/Icon/Icon.jsx";
import Group from "../components/Group/Group.jsx";
import Section from "../components/Section/Section.jsx";
import Text from "../components/Text/Text.jsx";
import TextInput from "../components/TextInput/TextInput.jsx";
import Select from "../components/Select/Select.jsx";

class CartForm extends React.Component {
  static CURRENCIES = [
    { value: "eur", label: "EUR" },
    { value: "usd", label: "USD" },
    { value: "gbp", label: "GBP" },
  ];

  render() {
    return (
      <>
        <Group direction="column" spacing={0}>
          <Section position="first">
            <Text size={16} color="dark">
              Configuracion del Carrito
            </Text>
          </Section>
          <Section position="middle">
            <Group direction="column">
              <Group
                direction="column"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "left",
                }}
              >
                <Text size={18} color="dark">
                  Moneda
                </Text>
                <Select
                  items={CartForm.CURRENCIES}
                  value={CartForm.CURRENCIES[0]}
                  onChange={this.props.onChangeCurrency}
                  ariaLabel="Moneda"
                />
              </Group>

              <Group
                direction="column"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "left",
                }}
              >
                <Text size={18} color="dark">
                  Descripción del artículo
                </Text>
                <TextInput
                  value={this.props.itemDescription}
                  onChange={this.props.onChangeItemDescription}
                  onKeyUp={this.props.onClickUpdateLineItems}
                  ariaLabel="Item description"
                />
              </Group>
              <Group
                direction="column"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "left",
                }}
              >
                <Text size={18} color="dark">
                  Importe
                </Text>
                <TextInput
                  value={this.props.chargeAmount}
                  onChange={this.props.onChangeChargeAmount}
                  onKeyUp={this.props.onClickUpdateLineItems}
                  ariaLabel="Import"
                />
              </Group>

              {/* <Button
                color="textDark"
                onClick={this.props.onClickUpdateLineItems}
                disabled={this.props.workFlowDisabled}
                justifyContent="left"
              >
                <Group direction="row">
                  <Icon icon="list" />
                  <Text color="white" size={18}>
                    Actualizar Lector
                  </Text>
                </Group>
              </Button> */}
            </Group>
          </Section>
        </Group>
      </>
    );
  }
}

export default CartForm;
